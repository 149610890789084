@import 'variables';
@import 'ma-controls';

.ma-modal :global {
  overflow-wrap: anywhere;

  .modal-content {
    border-radius: $border-radius-small;
    border-color: $border-colour;
  }

  .modal-body,
  .modal-footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .modal-header {
    padding-top: 1.25rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1.25rem;
    margin-right: 1.25rem;

    .modal-title {
      font-family: $be-vietnam-pro;
      font-size: 1.25rem;
      font-weight: 400;
    }

    .btn-close {
      opacity: 0.6;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .modal-footer {
    padding-bottom: 1.25rem;
    justify-content: center;
    border-top: none;

    .btn {
      border-radius: $border-radius-small;
      min-height: $ma-controls-min-height;
      min-width: fit-content;
    }
  }
}
