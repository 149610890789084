@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.table {
  min-width: 75rem;
  margin: 0;
  vertical-align: middle;

  thead {
    height: 2.5rem;

    tr {
      border-top: none;
    }

    th {
      background-color: $secondary-colour-light-blue;
      color: $base-colour-dark;
      white-space: nowrap;
      border: none;
      font-weight: normal;

      &.select {
        vertical-align: middle;
      }

      &.select,
      &.actions {
        text-align: center;
      }

      &.ordered,
      &.received,
      &.price,
      &.subtotal {
        text-align: right;
      }
    }
  }

  tbody {
    tr {
      height: 2.5rem;
    }

    td {
      border: none;

      &.product > a {
        @extend .primary-link;
      }

      &.due {
        white-space: nowrap;
      }

      &.select,
      &.actions {
        text-align: center;
      }

      &.ordered,
      &.received,
      &.price,
      &.subtotal {
        text-align: right;
      }
    }
  }

  th.select,
  td.select {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  th.actions,
  td.actions {
    position: sticky;
    right: 0;
  }

  tfoot {
    height: 2.5rem;
    border: none;

    tr {
      border-bottom: none;
    }

    td {
      border: none;
      background-color: $secondary-colour-light-blue;
    }
  }
}

.issues,
.note {
  margin-left: 0.25rem;
}
