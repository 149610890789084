@import '../scss/variables';
@import '../scss/ma-controls';

.ma-alert {
  :global(.modal-content) {
    border: 1px solid;
  }

  :global(.modal-body) {
    p {
      font-size: $body-font-size;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.info {
    :global(.modal-content) {
      border-color: $info;
    }

    .header {
      background-color: $info;
    }
  }

  &.warning {
    :global(.modal-content) {
      border-color: $warning;
    }

    .header {
      background-color: $warning;
    }
  }

  &.error {
    :global(.modal-content) {
      border-color: $danger;
    }

    .header {
      background-color: $danger;
    }
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 1rem 1.25rem;
    color: $white;

    h4 {
      margin: 0 0 0 1.5rem;
      font-size: 1.25rem;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    margin: 0 1rem;
    width: 12.5rem;
    min-width: fit-content;
    min-height: $ma-controls-min-height;
  }
}
