@import '../../../common/assets/scss/typography';
@import '../../../common/assets/scss/variables';

@include media-breakpoint-up(sm) {
  .dialog {
    width: 20rem;
  }
}

.body {
  font-size: $body-font-size;

  p {
    margin-bottom: 0.5rem;
  }
}

.tasks {
  ul {
    list-style: none;
    padding-left: 0;
    max-height: 18rem;
    overflow: auto;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    span {
      margin-top: 0.1rem;
    }

    &.unknown {
      color: $base-colour-dark-grey;
    }

    svg {
      min-width: fit-content;
      margin-left: 0.5rem;
    }

    &.outcome {
      margin-bottom: 1rem;
    }
  }

  .success {
    color: $primary-colour-light-blue;
  }

  .failure {
    color: $secondary-colour-red;
  }
}
