@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.order-request-spend-table {
  table {
    margin: 0;
    background-color: $white;
    border-color: $base-colour-light;
    vertical-align: middle;
    min-width: 75rem;
  }

  thead {
    height: 2.5rem;

    tr {
      border-top: none;
    }

    th {
      background-color: $secondary-colour-light-blue;
      white-space: nowrap;
      border: none;
      font-weight: normal;

      &.quantity,
      &.price,
      &.subtotal,
      &.total {
        text-align: right;
      }

      &.spend-categories,
      &.actions {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      height: 2.5rem;
    }
    td {
      border: none;

      &.product {
        span {
          padding: 0;

          .warning-icon {
            font-size: 1rem;
            color: $warning;
            margin-right: 0.5rem;
          }

          .danger-icon {
            font-size: 1rem;
            color: $danger;
            margin-right: 0.5rem;
          }
        }
      }

      &.product > a {
        @extend .primary-link;
      }

      &.inactive {
        a {
          color: $base-colour-dark-grey;
        }
        color: $base-colour-dark-grey;
      }

      &.quantity,
      &.price,
      &.subtotal {
        text-align: right;
      }

      &.spend-categories {
        text-align: center;
      }

      &.price,
      &.subtotal {
        text-wrap: nowrap;
      }

      &.actions {
        width: 4.5rem;

        :global(.spinner-border) {
          width: 1rem;
          height: 1rem;
        }

        div {
          display: flex;
          justify-content: space-between;
          width: 2.5rem;
          margin: auto;
        }
        button {
          min-width: 1rem;
          min-height: 1rem;

          svg {
            vertical-align: top;
          }

          &.remove {
            color: $base-colour-dark-grey;
          }
        }
      }
    }
  }

  .supplier-summary {
    td {
      background-color: $primary-colour-lightest;

      &.name {
        span:first-child {
          font-weight: 600;
          margin-right: 2rem;
        }
      }
    }
  }

  th.actions,
  td.actions {
    position: sticky;
    right: 0;
  }

  tfoot {
    height: 2.5rem;
    tr {
      border-bottom: none;
    }
    td {
      border: none;
      background-color: $secondary-colour-light-blue;
      font-weight: 700;

      &.items,
      &.total {
        text-align: right;
      }
    }
  }
}
