@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';
@import '../../../common/assets/scss/ma-controls';

.line-item,
.receive-order-line-item {
  @extend .body-font;

  display: flex;
  flex-direction: column;

  &.summary {
    flex-direction: row;
    gap: 1rem;
  }

  .subtle {
    color: $subtle;
  }

  .header {
    display: flex;
    justify-content: space-between;
    column-gap: 0.5rem;
  }

  .title-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }

  .title {
    display: flex;

    a {
      @extend .primary-link;
    }

    .name {
      @extend .subtitle-font;
      @extend .primary-link;

      padding-bottom: 1rem;
      overflow-wrap: anywhere;
    }
  }

  .issues,
  .note {
    margin-left: 0.5rem;
  }

  .edit-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 1rem;
    min-height: 1.25rem;
    height: fit-content;
  }

  .img {
    align-self: center;
    object-fit: contain;
    border: none;
    width: 5.5rem;
    min-width: 5.5rem;
    height: 5.5rem;
    max-height: 5.5rem;
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    &.summary {
      gap: 0;
      justify-content: space-around;
    }

    &.image-hidden {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      margin: 0;
    }

    th {
      color: $subtle;
      font-weight: normal;
      text-align: end;
      white-space: nowrap;
      vertical-align: top;
    }

    td {
      padding-left: 1rem;
    }

    .bold {
      font-weight: bold;
    }

    .middle-row {
      display: flex;
      flex-direction: row;
      column-gap: 2.5rem;
      flex-wrap: wrap;
    }

    .bottom-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &.mobile {
        flex-direction: column;
      }
    }

    .details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      align-items: flex-start;
      gap: 1rem;

      &.mobile {
        flex-direction: column;
        gap: 0;
      }
    }

    .details-group {
      margin-left: auto;
      text-align: end;
    }

    .spend-categories {
      display: flex;
      gap: 1rem;
      row-gap: 0.25rem;
      color: $subtle;
      flex-wrap: wrap;
      text-wrap: auto;

      .spend-category-list {
        display: flex;
        column-gap: 1rem;
        align-items: center;

        &.list-column {
          flex-direction: column;
          align-items: flex-start;
          row-gap: 0.25rem;
        }
      }

      .spend-category {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    }

    .delivery-and-receipt {
      width: 100%;
      text-align: end;

      &.mobile {
        text-align: start;
        text-wrap: auto;
      }
    }
  }

  .tracking-link {
    color: $link-color;
    font-weight: bold;
    text-decoration: none;
  }

  .quantities {
    margin-left: auto;
    margin-right: auto;
  }

  .receive-button {
    margin-left: auto;

    &.mobile {
      margin-top: 1rem;
      width: 100%;
    }

    :global(.btn) {
      min-height: $ma-controls-min-height;
    }
  }
}

.receive-order-line-item {
  .img {
    align-self: flex-start;
  }

  .content {
    flex-direction: row;
    flex-wrap: nowrap;

    &.mobile {
      flex-direction: column;
    }
  }

  .info {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    table {
      flex-grow: 1;
    }

    td {
      width: 100%;
      max-width: 0;
    }

    .quantities {
      margin: 0;

      td {
        width: 50%;
      }
    }

    .date {
      display: flex;
      justify-content: flex-end;
      min-width: fit-content;
      width: 14rem;
      margin-right: 2rem;

      table {
        flex-grow: 0;
      }

      td {
        width: fit-content;
        max-width: none;
      }
    }

    &.compact {
      flex-direction: column;

      table {
        width: 100%;
      }

      th {
        width: 30%;
        min-width: 6.5rem;
      }

      .quantities {
        td {
          width: auto;
        }
      }

      .date {
        width: 100%;
        margin: 0;
      }
    }
  }

  .receive-button {
    min-width: fit-content;
    width: 14rem;

    > * {
      width: 100%;
    }

    &.mobile {
      width: 100%;
    }
  }
}
