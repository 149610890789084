@import '../../../common/assets/scss/variables';

.quick-access {
  display: flex;
  position: relative;
  flex-direction: row;
  background-color: $tint-b;
  width: var(--left-sidebar-width); // set dynamically
}

.tabs {
  width: 3.5rem;
  min-width: 3.5rem;
  padding: 0;
  padding-top: 1.25rem;
  flex-grow: 1;

  :global {
    .btn {
      padding: 0;
      width: 3.5rem;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 0.375rem;
  height: $quick-access-header-height;
}

.title {
  font-size: 1.5rem;
  color: $primary-b;
  padding-left: 0.75rem;
  margin: 0;
}

.body {
  padding: 0 0.25rem;
  height: calc(100dvh - $main-header-height - $quick-access-header-height);

  p {
    font-size: $body-font-size;
    overflow-wrap: anywhere;
  }
}
