@import '../../../common/assets/scss/ma-controls';

.header {
  display: flex;
  justify-content: space-between;
}

.panel {
  margin-top: 1rem;
}

.panel-header {
  display: flex;
  column-gap: 0.5rem;

  h3 {
    font-size: 1rem;
  }
}

.button {
  min-height: $ma-controls-min-height;
}

.condition-editor {
  display: flex;
  gap: 1rem;

  div {
    flex: 1;
  }
}
