@import '../../../common/assets/scss/variables';

.groups {
  border-top: 1px solid $border-color;
  padding-top: 1.25rem;
}

.empty {
  text-align: center;
  padding-top: 2rem;
}
