@import '../../../common/assets/scss/variables';

.tasks {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  height: calc(100% - $quick-access-controls-height);
  overflow-y: auto;

  li {
    list-style: none;
  }
}
