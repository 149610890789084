@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.root {
  overflow-x: auto;
}

.graph {
  display: flex;
  justify-content: center;
  min-width: fit-content;
  margin: 0 3rem;
}

.node {
  display: flex;
  flex-direction: column;
  color: $base-colour-light;

  &.visited {
    color: $primary-colour-light-blue;
  }

  &.failure {
    color: $secondary-colour-red;
  }
}

.line {
  height: 4px;
  margin-top: 10px;
  min-width: 100px;
  max-width: 100px;
  background-color: $base-colour-light;

  &.visited {
    background-color: $primary-colour-light-blue;
  }
}

.label {
  display: flex;
  width: 24px;
  justify-content: center;
  text-align: center;
  margin-top: 0.5rem;
  border: 0;
  color: $primary-colour-light-blue;
  font-size: $body-font-size;
}
