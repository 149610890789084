@import '../../../common/assets/scss/variables';

.table {
  margin: 0;
  vertical-align: middle;
  overflow-wrap: anywhere;

  thead {
    height: 2.5rem;

    tr {
      border-top: none;
    }

    th {
      background-color: $secondary-colour-light-blue;
      color: $base-colour-dark;
      white-space: nowrap;
      border: none;
      font-weight: normal;

      &.name {
        padding-left: 1rem;
      }

      &.actions {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      height: 2.5rem;
    }

    td {
      border: none;

      &.name {
        padding-left: 1rem;
      }

      &.actions {
        text-align: center;
      }
    }
  }
}
