@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.modal {
  :global {
    .modal-header .modal-title {
      font-size: 1rem;
    }

    .modal-body {
      font-size: $body-font-size;
      padding-bottom: 1.25rem;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .modal-footer {
      padding-top: 1.25rem;
    }
  }
}

.summary {
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;
}

.placeholder,
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 14rem;
}

.error {
  color: $danger;
  margin-bottom: 0.25rem;
}

.products {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.product {
  display: flex;
  flex-direction: row;
  column-gap: 1.25rem;
  align-items: center;
  overflow-wrap: anywhere;

  &:first-child {
    margin-top: 1rem;
  }

  h6 {
    font-family: $be-vietnam-pro;
    font-size: $body-font-size;
    font-weight: 400;
    margin-bottom: 0;
  }

  .description {
    flex-grow: 1;
    border: 1px solid $border-colour;
    border-radius: $border-radius-small;
    padding: 0.625rem 0.75rem;
  }

  .supplier {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.25rem;
  }

  :global(.btn) {
    min-height: $ma-controls-min-height;
  }
}

.status {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  min-width: 3.75rem;
}

.success-icon {
  fill: $success;
}

.warning-icon {
  fill: $warning;
}
